import React from "react"

function Stats() {
    return (
      <div className="stats">
        <h3>Stats</h3>
      </div>
    )
  }

export default Stats;