import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function WalletMenu() {
  const [value, setValue] = useState("");
  const navigate = useNavigate();

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && value !=="") {
      navigate(`/account/${value}`);
    }
  }

  return(
    <div>
      <input 
        type="text" 
        className="WalletMenu" 
        placeholder="Your wallet address"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}
