
import React from 'react';
import MiningPage from '../elements/MiningPoolAll/MiningPage';

export default function Bitcoin() {

  return (
    <div>
      <MiningPage coin_or_token = "bitcoin" />
    </div>
  );
}
