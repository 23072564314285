import './App.css';
import { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation
} from "react-router-dom";

import Home from './other_subpages/Home.js';
import Calculator from './other_subpages/Calculator';
import Account from './other_subpages/Account.js';
import Faq from './other_subpages/Faq';
import Guides from './other_subpages/Guides';
import TermsAndConditions from './other_subpages/Terms_and_Conditions';
import PrivacyPolicy from './other_subpages/Privacy_Policy';

// Crypto
import Bitcoin from './coins_and_tokens/Bitcoin.js';
import EthereumClassic from './coins_and_tokens/EthereumClassic';
import Litecoin from './coins_and_tokens/Litecoin';
import Dash from './coins_and_tokens/Dash';
import Dogecoin from './coins_and_tokens/Dogecoin';
import BitcoinCash from './coins_and_tokens/BitcoinCash';
import EthereumPoW from './coins_and_tokens/EthereumPoW';
import Flux from './coins_and_tokens/Flux';
import IronFish from './coins_and_tokens/IronFish'
import Kadena from './coins_and_tokens/Kadena';
import Kaspa from './coins_and_tokens/Kaspa';
import Monero from './coins_and_tokens/Monero';
import Neoxa from './coins_and_tokens/Neoxa';
import Nexa from './coins_and_tokens/Nexa';
import OctaSpace from './coins_and_tokens/OctaSpace';
import Ravencoin from './coins_and_tokens/Ravencoin';
import Zcash from './coins_and_tokens/Zcash';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page on route change
  }, [location.pathname]);

  return null;
};

export default function App() {
  return (
    <Router>
      <ScrollToTop /> {/* This component handles scrolling to the top */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route path="/account/*" element={<Account />}/>
        <Route path="/faq" element={<Faq />} />
        <Route path="/guides" element={<Guides />}/>

        {/*Crypto*/}
        <Route path="/btc" element={<Bitcoin />} />
        <Route path="/bch" element={<BitcoinCash />} />
        <Route path="/dash" element={<Dash />} />
        <Route path="/doge" element={<Dogecoin />} />
        <Route path="/eth" element={<EthereumClassic />} />
        <Route path="/ethw" element={<EthereumPoW />} />
        <Route path="/flux" element={<Flux />} />
        <Route path="/iron" element={<IronFish />} />
        <Route path="/kda" element={<Kadena />} />
        <Route path="/kas" element={<Kaspa />} />
        <Route path="/ltc" element={<Litecoin />} />
        <Route path="/xmr" element={<Monero />} />
        <Route path="/neox" element={<Neoxa />} />
        <Route path="/nexa" element={<Nexa />} />
        <Route path="/octa" element={<OctaSpace />} />
        <Route path="/rvn" element={<Ravencoin />} />
        <Route path="/zec" element={<Zcash />} />

        <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/" />}/>
      </Routes>
    </Router>
  );
}
