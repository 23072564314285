import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import Bottom from '../elements/Bottom';

function Guides() {
  return (
    <div className='Guides'>
      <Header />
      <Stats />
      <h1>Guides</h1>
      <Bottom />
    </div>
  );
}

export default Guides;