
const GetCoinData = async ( id ) => {
    try {
        const response = await fetch(`https://api.coingecko.com/api/v3/coins/${ id }`);
        const data = await response.json();
        const price = data.market_data.current_price.usd;
        const name = data.name;
        const marketCap = data.market_data.market_cap.usd;
        const volume = data.market_data.total_volume.usd;
        const changePercentage24h = data.market_data.price_change_percentage_24h;

        return {
            price,
            name,
            marketCap,
            volume,
            changePercentage24h
        };
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
};

export default GetCoinData;