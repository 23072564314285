
import React, { useState, useEffect } from 'react';
import GetCoinData from './GetCoinData';
import Header from '../Header';
import Stats from '../Stats';
import Bottom from '../Bottom';

export default function MiningPage( coin_or_token ) {
    const serverConnectionInfo = 0;
    const networkHashrate = 0;
    const blockReward = 0;
    const minPayment = 0;
    const luck = 0;
    const networkDifficulty = 0;
    const poolFee = 0;
    const dagSize = 0;
    const [price, setPrice] = useState('');
    const [name, setName] = useState('');
    const [marketCap, setMarketCap] = useState('');
    const [volume, setVolume] = useState('');
    const [changePercentage24h, setChangePercentage24h] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            const coinData = await GetCoinData( 'bitcoin' ); // Replace 'bitcoin' with your desired crypto id
            setPrice(coinData.price);
            setName(coinData.name);
            setMarketCap(coinData.marketCap);
            setVolume(coinData.volume);
            setChangePercentage24h(coinData.changePercentage24h);
        };

        fetchData();
    }, []);

    return (
        <div className='MiningPage'>
            <Header />
            <Stats />
            <h1>{name}</h1>
            <div style={{display: 'flex', justifyContent: 'space-between', marginRight: 400}}>
                <h3>Server Connection Info: IP {serverConnectionInfo}</h3>
                <h3>Coin Price and Network Hashrate</h3>
            </div>
            <h3>Coin Price: ${price}</h3>
            <h3>Market Cap: ${marketCap}</h3>
            <h3>Volume: ${volume}</h3>
            <h3>24h Change: {changePercentage24h}%</h3>
            <h3>Network Hashrate: {networkHashrate} MH/s</h3>
            <h3>Block Reward: {blockReward}</h3>
            <h3>Minimum Payment: {minPayment}</h3>
            <h3>Luck: {luck}</h3>
            <h3>Network Difficulty: {networkDifficulty}</h3>
            <h3>Pool Fee %: {poolFee}</h3>
            <h3>DAG Size: {dagSize}</h3>
            <Bottom />
        </div>
    );
}
