import React from "react";
import Header from "../elements/Header";
import Stats from "../elements/Stats";
import MiningPoolHeader from "../elements/MiningPoolAll/MiningPoolHeader";
import MiningPool from "../elements/MiningPoolAll/MiningPool";
import Bottom from"../elements/Bottom";
import coinsAndTokens from "../elements/MiningPoolAll/list_of_coins_and_tokens";

export default function Home() {
    return (
        <div>
            <Header />
            <Stats />
            <MiningPoolHeader />
            {Object.entries(coinsAndTokens).map(([coin_or_token, {id, hashrate, income, income_24h, zil_combo, min_payout}]) => (
                <MiningPool 
                    coin_or_token={coin_or_token} 
                    id={id} 
                    hashrate={hashrate} 
                    income={income} 
                    income_24h={income_24h} 
                    zil_combo={zil_combo} 
                    min_payout={min_payout}
                />
            ))}
            <Bottom />
        </div>
    )
}
