
import React from 'react';
import MiningPage from '../elements/MiningPoolAll/MiningPage';

export default function EthereumClassic() {
  return (
    <div>
      <MiningPage coin_or_token = "ethereum" />
    </div>
  );
}