import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import CoinSelector from './CoinSelector';
import Bottom from '../elements/Bottom';

export default function Calculator() {
  return (
    <div className='Calculator'>
      <Header />
      <Stats />
      <h1>Calculator</h1>
      <CoinSelector />
      <div className='CalculatorHeadersAndInputs'>
        <h3 className='CalculatorRows'>Hashrate</h3>
        <h3 className='CalculatorRows'>Power</h3>
        <h3 className='CalculatorRows'>Electricity cost</h3>
      </div>
      <div className='CalculatorHeadersAndInputs'>
        <input type="text" placeholder="TH/s" className='CalculatorRows'/>
        <input type="text" placeholder="W" className='CalculatorRows'/>
        <input type="text" placeholder="$/KWh" className='CalculatorRows' />
      </div>
      <div className='CalculatorHeadersAndInputs'>
        <h3 className='CalculatorRows'>Fees</h3>
        <h3 className='CalculatorRows'>Hardware cost</h3>
        <h3 className='CalculatorRows'>Network Difficulty</h3>
      </div>
      <div className='CalculatorHeadersAndInputs'>
        <input type="text" placeholder="%" className='CalculatorRows'/>
        <input type="text" placeholder="$" className='CalculatorRows' />
        <input type="text" placeholder="Last 24h" className='CalculatorRows'/>
      </div>
      <div className='CalculatorButtons'>
        <button type="button" className='CalculatorButton' style={{marginRight: '250px'}}><h3>Reset</h3></button>
        <button type="button" className='CalculatorButton'><h3>Calculate</h3></button>
      </div>
      <Bottom />
    </div>
  );
}