import React from "react";

const MiningPoolHeader = () => {
    return(
      <div className="PoolHeader">
        <h1>Our Pools</h1>
        <h3>Pool Hashrate</h3>
        <h3>Network Hashrate</h3>
        <h3>Luck</h3>
        <h3>Network Difficulty</h3>
        <h3>Min Payout</h3>
      </div>
    )
}

export default MiningPoolHeader;
