import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import Bottom from '../elements/Bottom';

export default function TermsAndConditions() {
  return (
    <div className='TermsAndConditions'>
      <Header />
      <Stats />
      <h1>Terms and Conditions</h1>
      <Bottom />
    </div>
  );
}