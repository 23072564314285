import React from 'react';

export default function LanguageSelector() {
    const [isVisible, setIsVisible] = React.useState(false);

    const handleMouseEnter = () => {
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{ position: 'relative' }}
        >
            <h2
                style={{
                    cursor: "pointer",
                    textAlign: 'center',
                    marginRight: '20px'
                }}
            >
                Language
            </h2>
            {isVisible && (
                <div style={{ 
                    position: 'absolute', 
                    top: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    minWidth: '80px',
                    zIndex: 1,
                    textAlign: 'center'
                }}>
                    <h3>English</h3>
                    <h3>Español</h3>
                    <h3>Français</h3>
                </div>
            )}
        </div>
    );
}
