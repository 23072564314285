import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import Bottom from '../elements/Bottom';

export default function Faq() {
  return (
    <div className='Faq'>
      <Header />
      <Stats />
      <h1>FAQ</h1>
      <Bottom />
    </div>
  );
}