
import React from "react"
import { Link } from "react-router-dom";
import logo from '../images_and_logos/logo.png';
import WalletMenu from './WalletMenu';
import CurrencySelector from "./CurrencySelector";
import LanguageSelector from "./LanguageSelector";

export default function Header() {
  return (
    <div className="Header">
      <Link to="/">
        <img src={logo} alt="Logo" style={{marginLeft: "20px", width:"50px", height:"50px"}} />
      </Link>

      <h2>|</h2>

      <h2>Coin Prices</h2>

      <h2>|</h2>

      <Link to="/calculator">
        <h2>Calculator</h2>
      </Link>

      <WalletMenu />

      <Link to="/guides">
      <h2>Guides</h2>
      </Link>

      <h2>|</h2>

      <Link to="/faq">
        <h2>FAQ</h2>
      </Link>

      <h2>|</h2>

      <CurrencySelector />

      <h2>|</h2>
      
      <LanguageSelector />
    </div>
  )
}
