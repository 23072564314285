import React from 'react';
import coinsAndTokens from '../elements/MiningPoolAll/list_of_coins_and_tokens';

export default function CoinSelector() {
    const [isVisible, setIsVisible] = React.useState(false);
    const [selectedCoin, setSelectedCoin] = React.useState(null);

    const handleMouseEnter = () => {
        setIsVisible(true);
    };

    const handleMouseLeave = () => {
        setIsVisible(false);
    };

    const handleSelectCoin = (coin) => {
        setSelectedCoin(coin);
        setIsVisible(false);
    };

    return (
        <div 
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center'
            }}
        >
            <h2 style={{ cursor: "pointer" }}>
                Coin selected: {selectedCoin || "None"}
            </h2>
            {isVisible && (
                <div style={{ 
                    position: 'absolute', 
                    top: '100%',
                    zIndex: 1,
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    padding: '10px',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    minWidth: '120px',
                    maxHeight: '200px', // add a maximum height
                    overflowY: 'auto' // add a scrollbar if the content exceeds the maximum height
                }}>
                    {Object.entries(coinsAndTokens).map(([coin_or_token]) => (
                        <h3 
                            key={coin_or_token} 
                            onClick={() => handleSelectCoin(coin_or_token)}
                        >
                            {coin_or_token}
                        </h3>
                    ))}
                </div>
            )}
        </div>
    );
}
