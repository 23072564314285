import React from "react"
import { Link } from "react-router-dom";
import X from '../images_and_logos/X.png';
import Instagram from '../images_and_logos/Instagram.png';
import Telegram from '../images_and_logos/Telegram.png';
import Discord from '../images_and_logos/Discord.png';

const Bottom = () => {
    return (
      <div className="Bottom">
        <p>
          <div className="Logos">
            <a href="https://twitter.com/home">
              <img src={X} alt="X" style={{marginLeft: "20px", width:"20px", height:"20px"}} />
            </a> <br />
            <a href="https://www.instagram.com/">
              <img src={Instagram} alt="Instagram" style={{marginLeft: "20px", width:"20px", height:"20px"}} />
            </a> <br />
            <a href="https://www.telegram.com">
              <img src={Telegram} alt="Telegram" style={{marginLeft: "20px", width:"20px", height:"20px"}} />
            </a> <br />
            <a href="https://www.discord.com">
              <img src={Discord} alt="Discord" style={{marginLeft: "20px", width:"20px", height:"20px"}} />
            </a> <br />
          </div>
          <div className="otherLinks">
            <Link to="/terms_and_conditions">
              Terms and Conditions
            </Link> <br />
            <Link to="/privacy_policy">
              Privacy Policy
            </Link> | Copyright 2023. All Rights Reserved.<br />
            Social domain: Beak Miners OÜ | Eesti Harju Maakond, Tallinn, Kesklinna Linnaosa, Sakala Tn 7-2, 10141 <br />
          </div>
        </p>
      </div>
    )
}

export default Bottom;