import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import Bottom from '../elements/Bottom';

export default function PrivacyPolicy() {
  return (
    <div>
      <Header />
      <Stats />
      <h2>Privacy Policy</h2>
      <Bottom />
    </div>
  );
}