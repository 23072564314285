import React from 'react';
import Header from '../elements/Header';
import Stats from '../elements/Stats';
import Bottom from '../elements/Bottom';

export default function Account({ id }) {
  return (
    <div>
      <Header />
      <Stats />
      <div className='Account'>
        <h1>Miner Statistics {id}</h1>
        <h3>Unconfirmed balance</h3>
        <h3>Unpaid balance</h3>
        <h3>Current Hashrate</h3>
        <h3>Average Hashrate</h3>
        <h3>Your Round Share</h3>
        <h3>Total Paid</h3>
        <h3>Last 24 hours Reward</h3>
        <h3>Workers Online</h3>
        <h3>Last Share</h3>
        <h3>Valid Share Rate</h3>
      </div>
      <Bottom />
    </div>
  );
}
