let coinsAndTokens = {
    "Bitcoin": {"id": "btc", "id_cg": "bitcoin", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Ethereum Classic": {"id": "eth", "id_cg": "ethereum", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Litecoin": {"id": "ltc", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Dogecoin": {"id": "doge", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Monero": {"id": "xmr", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Bitcoin Cash": {"id": "bch", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Ethereum Proof of Work": {"id": "ethw", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Iron Fish": {"id": "iron", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Dash": {"id": "dash", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Flux": {"id": "flux", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Kadena": {"id": "kda", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Kaspa": {"id": "kas", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Neoxa": {"id": "neox", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Nexa": {"id": "nexa", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "OctaSpace": {"id": "octa", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Ravencoin": {"id": "rvn", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321},
    "Zcash": {"id": "zec", "hashrate": 123, "income": 456, "income_24h": 789, "zil_combo": "abc", "min_payout": 321}
};

export default coinsAndTokens;