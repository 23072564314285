import React from "react"
import { Link } from "react-router-dom";

function MiningPoolButton({ id }) {
    return(
      <div>
        <Link to={`/${id}`}>
          <button className="PoolButton">
            <h2>Join</h2>
          </button>
        </Link>
      </div>
    )
}

export default MiningPoolButton;