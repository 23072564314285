import React from "react";
import MiningPoolButton from './MiningPoolButton';

export default function MiningPool({ coin_or_token, id, hashrate, income, income_24h, zil_combo, min_payout }) {
    return (
      <div className="MiningPool">
        <h3>{coin_or_token}</h3>
        <h3>{hashrate}</h3>
        <h3>{income}</h3>
        <h3>{income_24h}</h3>
        <h3>{zil_combo}</h3>
        <h3>{min_payout}</h3>
        <MiningPoolButton id={id}/>
      </div>
    )
}
